import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Login(){

    const sessionId = String(sessionStorage.sessionId)
    const navigate = useNavigate()
    const [IsLoggedIn, setIsLoggedIn] = useState(false)



    const redirectSSo = () => {
        try {                
            window.location.replace('https://ctnapp-abi-rh-gpt-sso.agreeableisland-5ca145d1.eastus.azurecontainerapps.io');
        } catch (error) {
            console.error('Redirect sso:', error);
        }
    }

    return(
        <>
            <h1>Clique no botão abaixo para fazer o seu login Atos</h1>
            <button style={{backgroundColor: 'blue', color:'white', width: 60}} onClick={redirectSSo}>Logar</button>
        </>
    )
}

export default Login