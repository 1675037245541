import React from "react";

function Tutorial({abiLogo, tutorialStep, closeTutorial, previousTutorialStep, nextTutorialStep, tutorialRef, tutorialMessageRef, tutorialButtonsRef}){

    const finalStep = 10

    const tutorialMessages = [
      <p><b>Bem vindo ao Tutorial ABI 2.0!</b> Clique em <b>Iniciar Tutorial</b> para que eu te ensine como utilizar as minhas funcionalidades da melhor forma!</p>,
      <p>Aqui você encontra o botão para escolher entre temas claro ou escuro, assim você pode deixar do jeitinho que você gosta!</p>,
      <p>Aqui é a barra de conversa, onde você pode mandar suas dúvidas. Veja ao lado exemplos de como perguntar da forma <b>CORRETA</b> e <b>INCORRETA</b>.</p>,
      <p>Clique em <b>NOVO CHAT</b> sempre que quiser iniciar um <b>NOVO ASSUNTO</b> comigo! Lembre-se de usar a <b>FORMA CORRETA</b> que ensinei no passo <b>ANTERIOR</b> para realizar sua pergunta, ok?</p>,
      <p>Aqui estão os botões de avaliação <b>Like</b> e <b>Dislike</b> para você avaliar minha resposta! Sua avaliação é <b>SUPER</b> importante para mim, porque é com base nela que a <b>equipe que cuida de mim realiza os ajustes para eu continuar cada vez melhor!</b></p>,
      <p>Aqui é o seu espaço para mandar seu feedback sobre como estou me saindo, ou compartilhar suas idéias para que eu possa evoluir nas próximas versões. <b>Estou super curiosa para ouvir o que você tem a dizer!</b></p>,
      <p>Não esquece de caprichar nos <b>detalhes do seu feedback</b> e me passar seu <b>e-mail</b>, ok? Estou aqui para te ouvir!</p>,
      <p>E por último, a parte mais incrível: <b>eu mesma!</b> Hehe!</p>,
      <p>Sempre que quiser refazer o meu tutorial, é só clicar aqui.</p>,
      <p><b>Obrigada por participar do meu tutorial!</b> Agora, sinta-se à vontade para começar uma conversa comigo. Estou aqui para ajudar!</p>
    ]
  
    return(
      <div className="tutorial" ref={tutorialRef}>
        <div className="tutorial-container">
          <div className="tutorial-instructions">
            {tutorialStep !== 7 ? 
            <img src={abiLogo} className="logo-abi" alt="abi 2.0 beta"/>
            :
            <></>
            }
            <div className="tutorial-message" ref={tutorialMessageRef}>
                <button onClick={closeTutorial}>x</button>
                {tutorialMessages[tutorialStep]}
            </div>
          </div>
          <div className="tutorial-buttons" ref={tutorialButtonsRef}>
            {tutorialStep > 1 ? 
              <button className="tutorial-btn" onClick={previousTutorialStep}>Anterior</button>
              :
              <></>
            }
            <button className="tutorial-btn" onClick={nextTutorialStep}>
  
  
              {tutorialStep === finalStep ? "Finalizar" : tutorialStep === 0 ? "Iniciar Tutorial" : "Próximo"}
              </button>
            
          </div>

        </div>
        
        {tutorialStep === 2 ? 
        
        <div className="tutorial-tips-container">
          <div className="tip-container">
            <p className="tip-title">FORMA CORRETA</p>
            <ul>
              <li>Quais são os links para os treinamentos mandatórios?</li>
              <li>Como realizo o agendamento das férias? Como realizo o agendamento das férias?</li>
              <li>Onde acesso o meu holerite?</li>
            </ul>
          </div>
          <div className="tip-container">
            <p>FORMA INCORRETA</p>
            <ul>
              <li>Treinamentos</li>
              <li>Pagamento</li>
              <li>Vale transporte</li>
              <li>Sodexo</li>
            </ul>
          </div>
        </div>
        :
        <></>
      }
  
      </div>
    )
  }
  
  export default Tutorial