import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

function Sso() {
    const navigate = useNavigate();
    const { sessionId, email, uid } = useParams();
    
    useEffect(() => {
        sessionStorage.setItem('sessionId', sessionId);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('uid', uid);
        navigate('/');
    });

}

export default Sso