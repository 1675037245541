import { animateScroll } from "react-scroll";

function handleTutorial(
    inputRef, 
    tutorialRef, 
    switchRef, 
    reportRef, 
    newTopicRef, 
    likeRef, 
    dislikeRef, 
    tutorialBackgroundRef, 
    tutorialButtonRef, 
    abiLogoRef, 
    setTutorial, 
    setTutorialStep, 
    tutorialStep,
    openModal,
    closeModal
){
    
    let tutorialRefPosition;
    let switchRefPosition;
    let reportRefPosition;
    let newTopicRefPosition;
    let tutorialHeight;

    try {
        tutorialRefPosition = tutorialRef.current.getBoundingClientRect()
        switchRefPosition = switchRef.current.getBoundingClientRect()
        reportRefPosition = reportRef.current.getBoundingClientRect()
        newTopicRefPosition = newTopicRef.current.getBoundingClientRect()
        tutorialHeight = tutorialRefPosition.height
    } catch (error) {
    }
    
    switch(tutorialStep){
        // Switch button
        case 1:
            switchRef.current.style.zIndex = 1;
            inputRef.current.style.zIndex = 0;
            const bottomOfSwitch = switchRefPosition.y + switchRefPosition.height
            tutorialRef.current.style.transform = "none"
            tutorialRef.current.style.left = "unset"
            tutorialRef.current.style.right = "50px"
            tutorialRef.current.style.width = 'unset'
            tutorialRef.current.style.top = `${bottomOfSwitch + 20}px`
            
            break;
            
        // Text Input
        case 2:
            switchRef.current.style.zIndex = 0;
            newTopicRef.current.style.zIndex = 0
            inputRef.current.style.zIndex = 1;
            tutorialRef.current.style.left = "50%"
            tutorialRef.current.style.top = "50%"
            tutorialRef.current.style.transform = "translate(-50%, -50%)"
            tutorialRef.current.style.width = "90%"

            break;
          
        // New conversation Button
        case 3:
            likeRef.current.style.background = "transparent"
            dislikeRef.current.style.background = "transparent"
            tutorialRef.current.style.transform = "none"
            tutorialRef.current.style.width = "fit-content"
            likeRef.current.style.zIndex = 0
            dislikeRef.current.style.zIndex = 0
            inputRef.current.style.zIndex = 0
            newTopicRef.current.style.zIndex = 1
            const newTopicHeight = newTopicRefPosition.y
            tutorialRef.current.style.right = "unset"
            tutorialRef.current.style.left = "0px"
            tutorialRef.current.style.top = `${newTopicHeight - (tutorialHeight - 10)}px`
            
            break;
            
        // Like Dislike Button
        case 4:

            animateScroll.scrollToBottom({
                    containerId: "chatbot-container",
                    duration: 300,
                });
                
            newTopicRef.current.style.zIndex = 0
            reportRef.current.style.zIndex = 0;
            likeRef.current.style.zIndex = 1
            dislikeRef.current.style.zIndex = 1
            likeRef.current.style.background = "white"
            dislikeRef.current.style.background = "white"
            tutorialRef.current.style.left = "50%"
            tutorialRef.current.style.top = "50%"
            tutorialRef.current.style.transform = "translate(-50%, -50%)"
            tutorialRef.current.style.width = "90%"

            break;
          
        // Report Button
        case 5:
            tutorialRef.current.style.transform = "none"
            tutorialRef.current.style.width = "unset"
            tutorialBackgroundRef.current.style.visibility = "unset" 
            switchRef.current.style.zIndex = 0;
            reportRef.current.style.zIndex = 1;
            likeRef.current.style.background = "none"
            dislikeRef.current.style.background = "none"
            const bottomOfReport = reportRefPosition.y + reportRefPosition.height;
            tutorialRef.current.style.top = `${bottomOfReport + 20}px`
            tutorialRef.current.style.right = "unset"
            tutorialRef.current.style.left = `${reportRefPosition.x}px`

            break;
        
        // Report Modal
        case 6:
            tutorialBackgroundRef.current.style.visibility = "hidden" 
            reportRef.current.style.zIndex = 0;
            tutorialRef.current.style.top = "50%";
            tutorialRef.current.style.left = "unset";
            tutorialRef.current.style.right = "0px";
            
            openModal()
            
            break;              
            
        // ABI Logo
        case 7:
            tutorialBackgroundRef.current.style.visibility = "unset" 
        
            closeModal()
            
            const abiLogoPosition = abiLogoRef.current.getBoundingClientRect()
            abiLogoRef.current.style.zIndex = 1
            tutorialRef.current.style.top = `${abiLogoPosition.y}px`
            tutorialRef.current.style.right = "unset"
            tutorialRef.current.style.left = `${abiLogoPosition.x + abiLogoRef.current.width + 20}px`
            
            break;
        
        // Tutorial Button
        case 8:
            const tutorialButtonPosition = tutorialButtonRef.current.getBoundingClientRect()
            tutorialButtonRef.current.style.zIndex = 1
            tutorialRef.current.style.left = "unset"
            tutorialRef.current.style.right = "50px"
            tutorialRef.current.style.top = `${tutorialButtonPosition.y + 50}px`

            break;

        // End Tutorial
        case 9:
            setTutorial(false);
            setTutorialStep(0);
            sessionStorage.setItem('tutorial', false);
            break;
        
        // Unset styles when closing tutorial 
        default:
            try {
                switchRef.current.style.zIndex = 0;
                inputRef.current.style.zIndex = 0;
                newTopicRef.current.style.zIndex = 0;
                reportRef.current.style.zIndex = 0;
                tutorialButtonRef.current.style.zIndex = 0;
                likeRef.current.style.zIndex = 0;
                dislikeRef.current.style.zIndex = 0;
            } catch (error) {
            }
            break;
        }


}

export default handleTutorial;