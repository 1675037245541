import React, { useState, useLayoutEffect } from "react";
import { animateScroll } from "react-scroll";
import { TbSend } from "react-icons/tb";
import { RiThumbUpLine, RiThumbDownLine } from "react-icons/ri";
import { HiOutlineSun, HiOutlineMoon } from "react-icons/hi2";
import { BiErrorCircle } from "react-icons/bi";
import { RiChatNewLine } from "react-icons/ri";
import { create_conversation, sendMessage, messageResponse, sendFeedback } from "./Api.js";
import moment from "moment";
import atosDarkTheme from "../assets/atos-logo.svg";
import atosLightTheme from "../assets/atos-logo-light.svg";
import abiavatarDarkTheme from "../assets/abi-azul-nv.svg";
import abiavatarLightTheme from "../assets/abi-azul-nv.svg";
import abiAvatarCustom from "../assets/abiNovembroAzul.svg" // Avatar customizado, mudar caminho
import "../styles/UserScreen.css";
import Switch from "react-switch";
import pointer from "../assets/pointers.gif";
import pointerWhite from "../assets/pointers-white.gif";
import Modal from "./Modal";
import { v4 as uuidv4 } from "uuid";

console.log = function () { };
console.error = function () { };

function Chatbot() {
  const MENSAGEM_INICIAL =
    'Olá!\nEu sou a Abi 2.0 e estou aqui para tirar suas dúvidas de RH.\nUtilizo IA Generativa em minhas respostas, portanto, surpresas e erros podem acontecer.\nDica de ouro: Sempre valide as informações antes de tomar qualquer decisão importante e, caso encontre alguma resposta incorreta, você pode clicar no botão "Nova Conversa" para tentar novamente, o botão fica ao lado esquerdo do chat.\nVocê também pode nos ajudar clicando no botão "Enviar Feedback", na parte superior da tela.\nComo posso ajudar você hoje?';

  const [allMessages, setAllMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [userMessage, setUserMessage] = useState(false);
  const [conversationId, setConversationId] = useState("");
  const [lightTheme, setLightTheme] = useState(true);
  const sessionId = String(sessionStorage.sessionId);


  // const abiLogo = lightTheme ? abiavatarLightTheme : abiavatarDarkTheme;
  const abiLogo = lightTheme ? abiAvatarCustom : abiAvatarCustom;
  const atosLogo = lightTheme ? atosLightTheme : atosDarkTheme;
  const abiAvatar = lightTheme ? abiavatarLightTheme : abiavatarDarkTheme;
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [newChatHover, setNewChatHover] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useLayoutEffect(() => {
    const verificarLogin = async () => {
        try {
            if (sessionId === 'undefined') {
                setIsLoggedIn(false);
                window.location.replace('https://ctnapp-abi-rh-gpt-sso.agreeableisland-5ca145d1.eastus.azurecontainerapps.io');
                return;
            }
            setIsLoggedIn(true);
        } catch (error) {
            console.error('Verificar sessionId:', error);
            return;
        }
    };
    verificarLogin();  
    if (!conversationId) {
      createConversationId();
    }
  }, []);

  const createConversationId = async () => {
    // Método que cria uma conversa com o bot usando a API do Microsoft Bot Framework.
    try {
      const response = await create_conversation()

      if (response.status === 201) {
        setConversationId(response.conversationId);
        sendInitialBotMessage();
      } else {
        console.error(
          "Erro na resposta do servidor:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Erro ao enviar a mensagem inicial:", error);
    }

  };

  const sendInitialBotMessage = () => {
    // Método que cria um objeto de mensagem inicial do bot e atualiza o estado AllMessages.
    const botMessage = {
      text: <div style={{ whiteSpace: "pre-line" }}>{MENSAGEM_INICIAL}</div>,
      type: "bot-initial",
      time: getCurrentTime(),
      image: abiAvatar,
    };
    setAllMessages([botMessage]);
  };

  const sendUserMessage = () => {
    // Método que cria um objeto de mensagem do usuário, chama a func.'sendUserMessageToBot', atualiza o estado AllMessages.
    if (inputText.trim() === "") return;
    const userMessage = {
      questionId: uuidv4(),
      text: inputText,
      type: "user",
      time: getCurrentTime(),
    };
    sendUserMessageToBot(userMessage, conversationId);
    setInputText("");
    setUserMessage(true);
    setAllMessages((prevMessages) => [...prevMessages, userMessage]);
  };

  React.useEffect(() => {
    // Scroll para o final do chatbot-container
    animateScroll.scrollToBottom({
      containerId: "chatbot-container",
      duration: 300,
    });

  }, [allMessages]);
  const sendUserMessageToBot = async (userMessage, conversationId) => {
    try {
      const response = await sendMessage(
        "message",
        userMessage.questionId,
        userMessage.text,
        { id: conversationId },
        String(sessionStorage.email),
        String(sessionStorage.uid)

      );

      if (response.status === 200) {
        const newConversationId = response.id;
        conversationId = newConversationId;
        getResponseFromBot(conversationId)
          .then(({ id, message }) => {
            sendMessageBot(userMessage, id, message);
          })
          .catch((error) => {
            console.error("Erro ao buscar a mensagem do bot:", error);
          });
      } else {
        console.error(
          "Erro na resposta do servidor:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Erro ao enviar a mensagem do usuário:", error);
    }
  };


  const getResponseFromBot = async (conversationId) => {
    //Método que recupera as mensagens de resposta do bot
    try {
      const [convId, watermarkCode] = conversationId.split("|");
      const response = await messageResponse(
        convId,
        watermarkCode
      );
      if (response.status === 200) {
        let messages = response.messages;
        if (messages.length > 0) {
          return { id: messages[0].id, message: messages[0].text };
        } else {
          console.error("Nenhuma mensagem encontrada na resposta.");
        }
      }
    } catch (error) {
      console.error("Erro ao recuperar a mensagem do bot:", error);
    }
  };

  const isMarkdownLink = (text) => {
    // verifica se a string contém markdown
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/;
    return markdownLinkRegex.test(text);
  };

  const sendMessageBot = (question, id, response) => {
    // método que processa a resposta do bot, monta o objeto com a resposta e atualiza o estado AllMessages.
    setUserMessage(false);
    const messages = [];
    let currentText = response;

    while (isMarkdownLink(currentText)) {
      const match = currentText.match(/\[([^\]]+)\]\(([^)]+)\)/);
      if (match.index > 0) {
        // Texto antes do link
        var textoAntesDoLink = currentText.slice(0, match.index);
      }

      //Markdown
      const linkText = match[1];
      let linkURL = match[2];

      // Verificar se há um '.'
      const textoDepoisDoLink = currentText.slice(
        match.index + match[0].length
      );
      const dotAfterLink = textoDepoisDoLink.match(/^\s*\./);

      if (dotAfterLink) {
        linkURL += ".";
        currentText = textoDepoisDoLink.slice(dotAfterLink[0].length);
      } else {
        currentText = textoDepoisDoLink;
      }

      messages.push(
        <p key={messages.length}>
          {textoAntesDoLink}
          <a href={linkURL} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
        </p>
      );
    }

    // Adicionar qualquer texto restante
    if (currentText.length > 0) {
      const textLines = currentText.split("\n");
      textLines.forEach((line, index) => {
        messages.push(
          <span key={messages.length}>
            {line}
            {index < textLines.length - 1 && <br />}
          </span>
        );
      });
    }

    const botMessage = {
      id,
      question,
      text: messages,
      type: "bot",
      time: getCurrentTime(),
      image: abiAvatar,
    };

    setAllMessages((prevMessages) => [...prevMessages, botMessage]);
  };

  const handleInputChange = (e) => {
    //atualiza o estado do input com o valor que o usuário digitou no campo de entrada.
    setInputText(e.target.value);
  };

  const getCurrentTime = () => {
    return moment().format("HH:mm");
  };

  const feedbackButton = async (message, type) => {
    // Método que cria um objeto com os dados da avaliação like/dislike
    const data = {
      id: message.id,
      conversation_id: conversationId,
      feedback: type,
      question: message.question,
      gpt_response: message.text,
      timestamp: moment().format("DD-MM-YYYY HH:mm"),
      email: String(sessionStorage.email),
      das: String(sessionStorage.uid),
    };

    await sendFeedback(data);
    const messageIndex = allMessages.findIndex((m) => m.id === message.id);
    allMessages[messageIndex].feedback = { option: type };
    setAllMessages([...allMessages]);
  };


  const handleThemeChange = () => {
    //altera entre tema light e dark no <body>
    setLightTheme(!lightTheme);
    const bodyElement = document.body;
    if (lightTheme) {
      bodyElement.classList.remove("light-body");
      bodyElement.classList.add("dark-body");
    } else {
      bodyElement.classList.add("light-body");
      bodyElement.classList.remove("dark-body");
    }
  };

  const handleMouseEntering = () => {
    setNewChatHover(true);
  };
  const handleMouseLeaving = () => {
    setNewChatHover(false);
  };

  if (isLoggedIn === null) {
    // Ainda não temos a resposta da solicitação, você pode mostrar um indicador de carregamento aqui
    return <div>Verificando o status de login...</div>;
  } else if (isLoggedIn) {
    return (
      <>
        <div className={lightTheme ? "light-theme" : "dark-theme"} >
          <header>
            <div className="logo" style={{ display: "flex", gap: '5px' }}>
              <img src={abiLogo} className="logo-abi" alt="abi 2.0 beta" />
              <p style={{
                color: lightTheme ? "black" : "white",
                fontFamily: 'Biennale Bold (Headings)',
                fontWeight: 600,
                position: 'relative',
                top: '5px',
              }}>
                ABI <span style={{ fontSize: '14px'}}>2.0</span>
              </p>
            </div>

            <button className="report-button" onClick={openModal}>
              <BiErrorCircle className="error-icon" />
              Enviar Feedback
            </button>

            <div className="switch">
              <Switch
                onChange={handleThemeChange}
                checked={lightTheme}
                className="react-switch"
                onColor="#86d3ff"
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                uncheckedIcon={
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 20,
                    paddingRight: 2,
                  }}>
                    <HiOutlineSun />
                  </div>
                }
                checkedIcon={
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 20,
                    paddingRight: 2,
                  }}>
                    <HiOutlineMoon />
                  </div>
                }
              />
            </div>
          </header>

          <main>
            <div className="chatbot-container" id="chatbot-container">
              <div className="chatbot-messages">
                {allMessages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.type === "user" ? "user-message" : "bot-message"
                      }`}
                  >
                    {(message.type === "bot" ||
                      message.type === "bot-initial") &&
                      message.image && (
                        <img src={abiAvatar} alt="Bot" className="bot-image" />
                      )}

                    <div
                      className={`message-content ${message.type === "user" ? "user-text" : "bot-text"
                        }`}
                    >
                      {message.type === "bot" && (
                        <div className="bot-buttons">
                          <button
                            disabled={message.feedback?.option === "true"}
                            onClick={() => feedbackButton(message, "true")}
                            className={
                              message.feedback?.option === "true"
                                ? "disabled-button-like"
                                : lightTheme
                                  ? "light-button like-button"
                                  : "dark-button like-button"
                            }
                          >
                            <RiThumbUpLine className="like"></RiThumbUpLine>
                          </button>
                          <button
                            disabled={message.feedback?.option === "false"}
                            onClick={() => feedbackButton(message, "false")}
                            className={
                              message.feedback?.option === "false"
                                ? "disabled-button-dislike"
                                : lightTheme
                                  ? "light-button dislike-button"
                                  : "dark-button dislike-button"
                            }
                          >
                            <RiThumbDownLine className="dislike"></RiThumbDownLine>
                          </button>
                        </div>
                      )}
                      <div
                        className={`message-text ${message.type === "user"
                          ? "user-message-text"
                          : "bot-message-text"
                          }`}
                      >
                        {message.text}
                      </div>

                      <div className="message-time">{message.time}</div>
                    </div>
                  </div>
                ))}
              </div>
              {userMessage === true && (
                <div>
                  <img
                    src={lightTheme ? pointer : pointerWhite}
                    alt="digitando"
                    className="pointer"
                    id="pointer"
                  />
                </div>
              )}
            </div>
            <div className="input-send">
              <button
                className="new-topic-button"
                onClick={createConversationId}
                onMouseEnter={handleMouseEntering}
                onMouseLeave={handleMouseLeaving}
              >
                <RiChatNewLine className="new-topic-icon"></RiChatNewLine>
                {newChatHover ? "Nova conversa" : ""}
              </button>
              <div className="box-input-send">
                <input
                  className={lightTheme ? "light-input" : "dark-input"}
                  type="text"
                  placeholder="Digite sua dúvida (até 150 caracteres)"
                  value={inputText}
                  maxLength={150}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      sendUserMessage();
                    }
                  }}
                />
                <button
                  className={lightTheme ? "light-button" : "dark-button"}
                  type="button"
                  onClick={() => sendUserMessage()}
                >
                  <TbSend className="send-icon" />
                </button>
              </div>
            </div>
          </main>

          <Modal isOpen={modalOpen} onClose={closeModal} />
          <footer>
            <img
              src={atosLogo}
              className="footer-alinhamento Atos-logo"
              alt="logo atos"
            />
          </footer>
        </div>
      </>
    );
  } else {
    return null;
  }
}
export default Chatbot;
