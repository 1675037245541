import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import UserScreen from './screens/UserScreen';
import Login from './screens/Login';
import Sso from './screens/Sso'; 

function App() {
      return (
        <div className="App">
          <BrowserRouter>
              <Routes>
                <Route path='/' exact element={<UserScreen/>} />
                <Route path='/ssologin/:sessionId/:email/:uid' exact element={<Sso />} />
                <Route path='/login' exact element={<Login />} />
              </Routes>
            </BrowserRouter>
        </div>
      );
  };
export default App;
