import React, { useEffect, useState, useRef } from "react";
import "../styles/Modal.css";
import { FaRegCircleCheck } from "react-icons/fa6";

const Modal = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const modalRef = useRef(null);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email || !selectedOption || !feedback) {
      setErrorMessage("Por favor, preencha todos os campos antes de enviar.");
      return;
    }
    if (!email.endsWith("@atos.net")) {
      setErrorMessage("Por favor, use um email Atos para enviar seu feedback.");
      return;
    }

    // URL da API para a qual você deseja enviar os dados
    const apiURL = "https://abi-rh-gpt-final.azurewebsites.net/api/feedback_principal";

    // Dados que você deseja enviar
    //const data = { email, selectedOption, feedback };
    const data = {
      feedback: feedback,
      selectedOption: selectedOption,
      email: email,
    };

    // Opções para a solicitação fetch
    const requestOptions = {
      method: 'POST', // Método de envio
      headers: { 'Content-Type': 'application/json' },
      mode: "no-cors", // Tipo de conteúdo do corpo da solicitação
      body: JSON.stringify(data) // Converte os dados em uma string JSON
    };

    // Envia a solicitação para a API
    fetch(apiURL, requestOptions)
      .then(response => response.json()) // Converte a resposta em JSON
      .then(data => console.log(data))
      .then(setErrorMessage(null)) // Loga os dados recebidos
      .then(setSubmitSuccess(true))
      .then(setSelectedOption(""))
      .then(setEmail(""))
      .then(setFeedback(""))
      .catch(error => console.error('Error:', error)); // Loga qualquer erro que ocorra
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };


    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          onClose();
        }
      };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const closeModal = () =>{
    onClose()
    setSubmitSuccess(false)
  }
  

  return (
    <div
      className="modal"
      ref={modalRef}
      role="dialog"
      aria-labelledby="modalTitle"
      onClick={onClose}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <div className="container">
          {submitSuccess ? (
            
            <>

            <div className="box" style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          textAlign: 'center',
                      }}>
                        <FaRegCircleCheck
                        fontSize={50}
                        color="#01005E"/>    
                        <h4 style={{
                            fontSize: '20px',
                            marginTop: '5px',
                            marginBottom: '-8px'
                        }}>Feedback coletado!</h4>
                          <p style={{ 
                            color: 'rgb(61, 61, 61)',
                            fontSize: '15px'        
                          }}
                            >Muito obrigado pelo seu feedback. <br />
                            Esse processo é essencial para nosso aprimoramento</p>
                      </div></>
          ) : (
            <>
              <h2 className="titulo" id="modalTitle">
                Enviar feedback
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="box-input">
                  <label>Email Atos: </label>
                  <input
                    type="text"
                    className="input_email"
                    value={email}
                    placeholder="Digite seu email aqui"
                    onChange={handleEmailChange}
                  />
                </div>

                <div className="inp-radio">
                  Sugestão
                  <label>
                    <input
                      type="radio"
                      value="sugestao"
                      checked={selectedOption === "sugestao"}
                      onChange={handleOptionChange}
                    />
                  </label>
                </div>

                <div className="inp-radio">
                  Elogio
                  <label>
                    <input
                      type="radio"
                      value="elogio"
                      checked={selectedOption === "elogio"}
                      onChange={handleOptionChange}
                    />
                  </label>
                </div>

                <div className="inp-radio">
                  Reportar erro
                  <label>
                    <input
                      type="radio"
                      value="erro"
                      checked={selectedOption === "erro"}
                      onChange={handleOptionChange}
                    />
                  </label>
                </div>

                <div className="box-input">
                  <label>Feedback: </label>
                  <textarea
                    className="textarea-feedback"
                    maxLength={1000}
                    placeholder="Max. 1000 caracteres"
                    rows={4}
                    cols={50}
                    value={feedback}
                    onChange={handleFeedbackChange}
                  ></textarea>
                </div>


                {errorMessage && <label>{errorMessage}</label>}
                {/* {submitSuccess && <label>Agradecemos pelo seu feedback, saiba que ele é muito importante para melhorarmos e evoluirmos cada vez mais!&#128516;</label>} */}
                <button type="submit">Enviar</button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Modal;
