// import { is } from "date-fns/locale";

const BACKEND_API = "https://abi-rh-gpt-final.azurewebsites.net/api/";
//const BACKEND_API = "http://localhost:3978/api/";

export async function create_conversation(token) {
    const response = await fetch(`${BACKEND_API}createconversation`,
        {
        method: "POST",
        headers: {            
            "Content-Type": "application/json",
        },        
    });
    const status = response.status;        
    const json = await response.json();
    return { status, ...json };
}

export async function sendMessage(type,questionId,text,from,email,das ) {    
    const response = await fetch(`${BACKEND_API}sendmessage`,
        {
        method: "POST",
        headers: {            
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ type,questionId,text,from,email,das }),        
    });        
    const status = response.status;        
    const json = await response.json();
    return { status, ...json };
}

export async function messageResponse( convId, watermarkCode ) {    
    const response = await fetch(`${BACKEND_API}messageresponse`,
        {
        method: "POST",
        headers: {            
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ convId, watermarkCode }),        
    });
    const status = response.status;        
    const json = await response.json();
    return { status, ...json };
}

export async function sendFeedback(data) {    
    await fetch(`${BACKEND_API}feedback`,
        {
        method: "POST",
        headers: {            
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        mode: "no-cors",
    });    

}